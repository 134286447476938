import React, { Component, Fragment } from "react";
import { styled } from "../stitches.config";
import { observer, inject } from "mobx-react";

//components
import Adslot from "./Adslot";
import PostsList from "./PostsList";
import PostsListBizon from "./PostsListBizon";
import BlockList from "./common/BlockList";

import {
  IS_FEATURE_adunityPartner,
  IS_FEATURE_PAGINATOR_LIST
} from "./common/constants/constants"
import ScrollToTop from "../helpers/ScrollToTop";

import PaginatorPosts from "../redesign/PaginatorPosts";

@inject("UIStore")
@observer
export default class Category extends Component {

  render() {
    const { category, store, UIStore } = this.props;
    const categoryBlocks = category.wizard;
    const categoryName = category.name;

    // console.log('[ES] Category', store.status);
    if (store.status !== 'valid') {
      return null;
    }
    // console.log('[ES] Category // render', category, categoryBlocks, categoryName);

    // generuje prefix dla reklam
    const isHomePage = category.OID === 0;
    let adPrefix = "KAT";
    if (category.type === "bliss") {
      adPrefix = "bliss"; //TODO co z tym nazewnictwem?
    } else if (category.type === "trudat") {
      adPrefix = "trudat"; //TODO jw
    } else if (isHomePage) {
      adPrefix = "SG";
    }

    // generuje suffix dla reklam
    const isMaterialPartnera = UIStore.isMaterialPartnera;
    const adSuffix = ((IS_FEATURE_adunityPartner && isMaterialPartnera) ? '_partner' : '');

    // ukrycie billboard_1 jak jest włączony b-one-super
    const hideBillboard1 = (categoryBlocks && categoryBlocks[0] && categoryBlocks[0].type == 'b-one-super');

    const ssrFlag = window.APP_SETTINGS.feature_flags_extended?.['ssr'];
    const ssrFlagSettings = ssrFlag?.extended_value;
    let staticBlockToShow = ssrFlag?.value == 1 ? (ssrFlagSettings.category_render_blocks?.[category.OID] ?? 0) : 0;

    if (category.OID == 0 && !ssrFlagSettings.home_page)
      staticBlockToShow = 0;

    return (
      <Fragment key="home-page-container">
        <ScrollToTop />
        <Adslot name={"belka_sticky" + adSuffix} />
        {hideBillboard1 || <Adslot name={adPrefix + "_Billboard_1" + adSuffix} />}

        <div className="hp" data-lastmod={store.lastModification}>
          {false && category.show_category_title &&
            category.type !== "trudat" && (
              <CategoryType isContact={category.slug === "kontakt"}>
                {" "}{categoryName}{" "}
              </CategoryType>
            )}

          <BlockList settings={categoryBlocks} adPrefix={adPrefix} adSuffix={adSuffix} staticShow={staticBlockToShow} />
        </div>

        {category.last_posts.length > 0 &&
          (
            <>
              <PaginatorPosts label="Najnowsze" id={category.OID} dataType="category" type="list"
                paginationType={IS_FEATURE_PAGINATOR_LIST ? 'PAGINATOR' : 'INFINITE_LIST'}
              />
            </>
          )
        }
      </Fragment>
    );
  }
};

const CategoryType = styled("div", {
  backgroundColor: "$primary",
  textAlign: "center",
  color: "#fff",
  fontSize: "22px",
  fontWeight: "bold",
  padding: "10px",
  paddingBottom: "19px",
  position: "relative",
  zIndex: -1,
  bottom: "-9px",
  marginBottom: "0",
  variants: {
    "isContact": {
      true: {
        paddingBottom: "12px",
        marginBottom: "29px"
      },
      false: {}
    }
  }
});